import Swiper from "swiper";
import { Navigation, Pagination, Thumbs, Scrollbar } from "swiper/modules";

// Swiper
window.Swiper = Swiper;
window.SwiperModules = {
    navigation: Navigation,
    pagination: Pagination,
    thumbs: Thumbs,
    scrollbar: Scrollbar,
};

//get all recomendation slider image
const slidersImages = document.querySelectorAll(".products-list .image");
//add slider for each of items
slidersImages.forEach((el) => {
    const id = el.dataset.id;
    const selector = `.products-list .image[data-id="${id}"]`;
    const navigation = selector + " .inner-slider-navigation";
    const instance = new Swiper(selector, {
        slidesPerView: "auto",
        loop: true,
        navigation: {
            prevEl: navigation + " .prev ",
            nextEl: navigation + " .next",
        },
        modules: [Navigation],
    });
});

// NEW PRODUCT ITEM ============================================================
const newProductsItem = document.querySelectorAll(
    ".new-product-item.product-item",
);
if (newProductsItem) {
    newProductsItem.forEach((itemEle) => {
        const swiperEle = itemEle.querySelector(".product-item__gallery");
        new Swiper(swiperEle, {
            slidesPerView: "auto",
            // slidesPerView: 1,
            slidesOffsetBefore: 1, // Add offset before
            // loop: true,
            rewind: true,
            allowTouchMove: false,
            navigation: {
                prevEl: ".product-item__gallery-navigation .prev",
                nextEl: ".product-item__gallery-navigation .next",
            },
            modules: [SwiperModules.navigation],
        });
    });
}
